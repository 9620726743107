/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import {
  Page,
  H1,
  Flex,
  Paper,
  FeatherIcon,
  Text,
  Box,
  Hr,
  Badge,
} from '../../elements'
import { GuestEntry, SEO, Badges, FiltersButtons } from '../../components'
import { useFilters } from '../../utils'

const canFilter = false

const Guests = ({
  data: {
    guests: { nodes: guests },
    filters: { nodes: filtersArray },
  },
}) => {
  const {
    filterValues: { onlyFavorites, ...filterValues },
    setFilterValue,
    resetFilters,
    setFilterList,
    filters,
    filtersOpen,
    setFiltersOpen,
    filtersSelected,
  } = useFilters(
    'guests',
    {
      // categories: null,
      onlyFavorites: false,
    },
    filtersArray,
  )

  const color = 'guests'

  return (
    <Page>
      {({ t }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `/guests/`,
              title: t('guests'),
              // description: true,
            }}
          />
          <Paper
            {...{
              bg: 'guest',
            }}
          >
            <Flex.container
              {...{
                alignItems: 'center',
                py: 0,
                css: {
                  cursor: canFilter ? 'pointer' : 'auto',
                },
                ...(canFilter && {
                  onClick: () => {
                    setFiltersOpen(!filtersOpen)
                  },
                }),
              }}
            >
              <H1
                {...{
                  color: 'textAlt',
                  // px: [2, 2, 3],
                  // pl: 2,
                  css: {
                    flexGrow: 1,
                  },
                }}
              >
                {t('guests')}
              </H1>
              {canFilter && filtersSelected.length > 0 ? (
                <Badge
                  {...{
                    borderRadius: 3,
                    mx: 2,
                    my: '1px',
                    p: null,
                    px: 2,
                    py: '2px',
                    // mr: 1,
                    color,
                    bg: 'textAlt',
                    fontSize: 1,
                  }}
                >
                  {filtersSelected.length > 1
                    ? filtersSelected.length
                    : t(filtersSelected[0]) || '1'}
                </Badge>
              ) : (
                canFilter &&
                !onlyFavorites && (
                  <Text {...{ color: 'textAlt', m: null, mx: 2 }}>
                    {t('filters')}
                  </Text>
                )
              )}
              {canFilter && onlyFavorites && (
                <Flex
                  {...{
                    width: 3,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'textAlt',
                    border: 2,
                    borderColor: 'textAlt',
                    mr: 2,
                    css: {
                      cursor: 'pointer',
                      borderRadius: '100%',
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: color,
                      fill: 'textAlt',
                      // fill: color,
                      // size: 3,
                      size: 2,
                      // mr: 1,
                      // mb: 1,
                      css: {
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        padding: 1,
                      },
                    }}
                  />
                </Flex>
              )}
              {canFilter && (
                <FeatherIcon
                  {...{
                    icon: filtersOpen ? 'arrow-up' : 'arrow-down',
                    color: 'textAlt',
                    size: 4,
                  }}
                />
              )}
            </Flex.container>
            {canFilter && (
              <Box.container
                {...{
                  color: 'textAlt',
                  py: 0,
                  maxHeight: filtersOpen ? '80vh' : '0px',
                  css: {
                    transition: 'max-height .7s ease-out',
                    overflow: 'hidden',
                  },
                }}
              >
                <Hr
                  {...{ border: 0, borderBottom: 1, borderColor: 'textAlt' }}
                />
                <FiltersButtons
                  {...{
                    setFilterValue,
                    setFiltersOpen,
                    resetFilters,
                    color,
                    onlyFavorites,
                  }}
                />
              </Box.container>
            )}
          </Paper>
          <Flex
            {...{
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              px: 0,
            }}
          >
            {guests.map((e, i) => {
              if (!e) return null

              return (
                <GuestEntry
                  {...{
                    key: e.id,
                    guest: e,
                    filterValues,
                    onlyFavorites,
                  }}
                />
              )
            })}
          </Flex>
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query Guests {
    guests: allGuest(
      filter: { status: { eq: "published" } }
      sort: { fields: [nameCanonical] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
      }
    }
    filters: allFilter(filter: { onType: { eq: "guest" } }) {
      nodes {
        name
        values
        minValue
        maxValue
      }
    }
  }
`

export default Guests
